import React from 'react';
import './ImagePopup.css'; // Create this CSS file for styling

const ImagePopup = ({ imageUrl, onClose }) => {
  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <img src={imageUrl} alt="Enlarged view" className="popup-image" />
        <button className="close-button" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default ImagePopup;
