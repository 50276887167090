import React from 'react';
import logo from './logo.png'; // Ensure to add the path for your logo image

function Navbar() {
  return (
    <nav className="NavBar">
      <ul className="navbargrad">
        <li className="NavButton">
          <img src={logo} alt="Logo" className="MainLogo" />
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
