import React, { useState } from 'react';
import FileInput from './FileInput';

function UploadSection({ handleUpload }) {
  const [selectedFiles, setSelectedFiles] = useState(null);

  const handleFileChange = (files) => {
    console.log("file change" + files.length)
    setSelectedFiles(files); // Store the selected files in state
  };

  const handleUploadClick = () => {
      console.log(selectedFiles);
    if (selectedFiles && selectedFiles.length > 0) {
      handleUpload(selectedFiles); // Call the upload function with the selected files
    } else {
      alert('Please select files to upload.');
    }
  };

  return (
    <div id="uploadSection" className="w-full text-center">
      <FileInput onFileChange={handleFileChange} />
      <button 
        className="btn btn-primary m-1" // Bootstrap styling for a nicer button
        onClick={handleUploadClick}
      >
        Upload Files
      </button>
    </div>
  );
}

export default UploadSection;
