import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons';

function PasswordSection({ verifyGuest }) {
  const [password, setPassword] = useState('');

  const handleSubmit = () => {
    verifyGuest(password);
  };

  return (
    <div id="password-parent">
      <div className="password-container">
        <input
          type="text"
          className=' password_input'
          placeholder="Please enter your name"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}
        />
        <button onClick={handleSubmit} id='enter_icon'>
        <FontAwesomeIcon icon={faRightToBracket} />
        </button>
      </div>
    </div>
  );
}

export default PasswordSection;
