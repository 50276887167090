import React, { useState, useEffect } from 'react';
import { storage } from './firebase';
import { ref, uploadBytes } from 'firebase/storage';
import Navbar from './components/Navbar';
import PasswordSection from './components/PasswordSection';
import UploadSection from './components/UploadSection';
import ApprovedFiles from './components/ApprovedFiles';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';


function App() {
  const [guestName, setGuestName] = useState(localStorage.getItem('guestName') || '');
  const [guestList, setGuestList] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(guestList.includes(guestName));
  const [refresh, setRefresh] = useState(false); // Refresh toggle
  const [isAdmin, setIsAdmin] = useState(false); // State to track admin status
  

   // Fetch the guest list when the component mounts
   useEffect(() => {
    const fetchGuestList = async () => {
      try {
        const response = await fetch('/data/guestData.json'); // Adjust the path as necessary
        const data = await response.json();
        setGuestList(data);
        
        // After fetching the guest list, check local storage
        const storedGuestName = localStorage.getItem('guestName');
        if (storedGuestName) {
          setGuestName(storedGuestName);
          // Verify the stored name if it exists
          setIsAuthenticated(true);
        }
      } catch (error) {
        console.error('Error fetching guest list:', error);
      }
    };

    fetchGuestList();
  }, []); // Empty dependency array ensures this runs only once when the component mounts

   // Verify Guest Name
   const verifyGuest = (name) => {
    // Normalize the guestName input by trimming spaces and converting to lowercase
    const normalizedGuestName = name.trim().toLowerCase();
  
    // Find the properly formatted name in guestList that matches the input
    const matchedGuest = guestList.find(
      guest => guest.toLowerCase() === normalizedGuestName
    );
  
    if (matchedGuest) {
      alert(`Welcome ${matchedGuest}`);  // Use the correctly formatted name
      setGuestName(matchedGuest);         // Store formatted name in state
      setIsAuthenticated(true);
  
      // Update local storage with the properly formatted name
      localStorage.setItem('guestName', matchedGuest);
  
      // Check if the normalized name is 'joeybob123' for admin access
      if (normalizedGuestName === 'joeybob123') {
        setIsAdmin(true);
        localStorage.setItem('admin', true); // Store admin status in local storage
      } else {
        setIsAdmin(false);
        localStorage.setItem('admin', false); // Store admin status in local storage
      }
    } else {
      alert(`Sorry, ${name}, you are not on the guest list.`);
      setIsAuthenticated(false);
    }
  };
  
  

  const handleUpload = async (files) => {
    console.log(guestName);
    
    // Check if guestName is set and files array is not empty
    if (!guestName || files.length === 0) {
      alert('Please select files to upload.');
      return;
    }
  
    // Convert files to an array and filter to include only image types
    const imageFiles = Array.from(files).filter(file => file.type.startsWith('image/'));
  
    // Check if there are any image files to upload
    if (imageFiles.length === 0) {
      alert('Please select only image files to upload.');
      return;
    }
  
    // Process each image file for upload
    imageFiles.forEach(async (file) => {
      const fileRef = ref(storage, `uploads/${file.name}`);
      
      const metadata = {
        customMetadata: {
          uploadedBy: guestName
        }
      };
      
      try {
        await uploadBytes(fileRef, file, metadata);
        alert(`${file.name} uploaded successfully!`);
        setRefresh((prev) => !prev); // Trigger a refresh after upload
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    });
  };
  

  const refreshGallery = () => {
    
    setRefresh((prev) => !prev)
  }; // Manual refresh toggle

  return (
    <div className="App" id = "background">
      <Navbar />
      <div className="content-wrapper">
        {!isAuthenticated ? (
          <PasswordSection verifyGuest={verifyGuest} />
        ) : (
          <div>
            <UploadSection handleUpload={handleUpload} />
            <ApprovedFiles guestName={guestName} refresh={refresh} onRefresh={refreshGallery} />
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
