import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserLargeSlash } from '@fortawesome/free-solid-svg-icons';

const LogOutButton = ({ onLogOut }) => {

    const handleLogout = () => {
        localStorage.removeItem('guestName');
        localStorage.removeItem('admin'); // Remove admin flag if necessary
        window.location.reload(); // Refresh to simulate logout and redirect to login
      };


  return (
    <button 
      className="btn btn-secondary refresh-button d-flex align-items-center mx-auto mb-10" // Bootstrap styling for a nicer button
      onClick={handleLogout}
    >
      <FontAwesomeIcon icon={faUserLargeSlash} className="me-2" /> {/* Font Awesome icon */}
      Logout
    </button>
  );
};

export default LogOutButton;
