import React from 'react';

const FileInput = ({ onFileChange }) => {
  const handleFileChange = (event) => {
    const files = event.target.files;
    // Call the onFileChange prop with the selected files
    if (onFileChange) {
      onFileChange(files);
    }
  };

  return (
    <div className="mb-3 FileInput">
      <label htmlFor="formFile" className="form-label">Select Photos!</label>
      <input
        className="form-control"
        id="formFile"
        type="file"
        onChange={handleFileChange}
        multiple
        accept="image/*"
      />
    </div>
  );
};

export default FileInput;
