import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';

const RefreshButton = ({ onRefresh }) => {
  return (
    <button 
      className="btn btn-secondary refresh-button d-flex align-items-center mx-auto mb-10" // Bootstrap styling for a nicer button
      onClick={() => {
        onRefresh()
      }}
    >
      <FontAwesomeIcon icon={faSync} className="me-2" /> {/* Font Awesome icon */}
      Refresh
    </button>
  );
};

export default RefreshButton;
