import React, { useEffect, useState } from 'react';
import { storage } from '../firebase';
import { ref, listAll, getDownloadURL, getMetadata, deleteObject } from 'firebase/storage';
import './ApprovedFiles.css';
import ImagePopup from './ImagePopup'; // Import the new ImagePopup component
import RefreshButton from './RefreshButton';
import LogOutButton from './LogOutButton';

function ApprovedFiles({ guestName, refresh, onRefresh }) {
  const [files, setFiles] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    const fetchFiles = async () => {
      const folderRef = ref(storage, `uploads/`);
      const fileList = await listAll(folderRef);
  
      const filesWithMetadata = await Promise.all(
        fileList.items.map(async (fileRef) => {
          // Get the file's URL and metadata
          const url = await getDownloadURL(fileRef);
          const metadata = await getMetadata(fileRef);
          
          // Skip files that are videos
          if (metadata.contentType.startsWith('video/')) {
            console.log(`Skipping video file: ${fileRef.name}`);
            return null; // Skip this file by returning null
          }
  
          // Get the uploader's name
          const uploadedBy = metadata.customMetadata?.uploadedBy || 'Unknown';
  
          // Create an image object to determine orientation
          const img = new Image();
          img.src = url;
          
          // Wait for the image to load to get dimensions
          await new Promise((resolve) => (img.onload = resolve));
          const orientation = img.width > img.height ? 'landscape' : 'portrait';
  
          // Return file data as an object
          return { url, uploadedBy, orientation, fileRef };
        })
      );
  
      // Filter out null values (video files) from the results
      const filteredFiles = filesWithMetadata.filter(file => file !== null);
      setFiles(filteredFiles);
    };
  
    fetchFiles();
  }, [guestName, refresh]);
  

  const handleDelete = async (fileRef) => {
    if (window.confirm("Are you sure you want to delete this file?")) {
      await deleteObject(fileRef);
      onRefresh();
    }
  };

  const handleImageClick = (url) => {
    setSelectedImage(url);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setSelectedImage(null);
  };

  return (
    <div id="approvedFilesSection" className="approved-files">
      <div className='buttonGroup'>
        <RefreshButton onRefresh={() => {
          onRefresh()}}></RefreshButton>
        <LogOutButton></LogOutButton>
      </div>
      
      <div className="file-gallery">
        {files.map((file, index) => (
          <div key={index} className={`file-item ${file.orientation}`}>
            <img
              src={file.url}
              alt="Approved file"
              className="gallery-image"
              onClick={() => handleImageClick(file.url)} // Open popup on click
            />
            <p className="uploaded-by">Uploaded by: {file.uploadedBy}</p>
            {localStorage.getItem('admin') === 'true' && (
              <button 
                className="delete-button" 
                onClick={() => handleDelete(file.fileRef)}>
                Delete
              </button>
            )}
          </div>
        ))}
      </div>

      {isPopupOpen && (
        <ImagePopup imageUrl={selectedImage} onClose={closePopup} />
      )}
    </div>
  );
}

export default ApprovedFiles;
